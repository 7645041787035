.sections__wrapper{
    /* padding:40px; */
    /* display: flex; */
    /* gap:40px */
}
.navbar__wrapper{
    /* width:300px */
}
.content__wrapper{
    /* max-width: calc(100vw - 420px); */
    /* flex-grow: 1; */
    /* position: relative; */
}
.navbar__responsive-button{
    display: none;
    /* position: absolute;
    top:5px;
    left:0; */
}
.navbar__wrapper--responsive{
    display: none;
    align-items: center;
    justify-content: space-between;
    padding:20px 0
}
.navbar__responsive-logo{
    width: 80px;
}
@media (max-width:1400px) {
    .sidebar__wrapper{
        display: none;
    }
    .content__wrapper{
        max-width: 100%;
        flex-grow: 1;
    }
}
@media (max-width:991px) {
    .sections__wrapper{
        padding:20px;
    }
    .content__wrapper{
        max-width: 100%;
    }
    .navbar__wrapper{
        width:300px
    }
}
@media (max-width:991px) {
    .navbar__responsive-button{
        display: flex;
    }
    .navbar__wrapper{
        width: 0;
        position: absolute;
    }
    .navbar__wrapper--responsive{
        display: flex;
    }
}