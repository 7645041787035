.client-details{
    background: url('/src/assets/imgs/loginBG.png') left no-repeat;
    background-size: contain;
}
.client-details__header-wrapper{
    display:flex;
    align-items: center;
    justify-content:flex-end;
    gap: 64px;
    margin-bottom: 40px;
}

.client-details__header-link{
    color: #5885C3;
    border-bottom: 2px solid #AAAAAA!important;
    font-family: 'InterSemiBold';
    padding-bottom: 8px;
    font-size: 30px;
    display: flex;
    align-items: center;
    gap:15px;
}
body[dir='rtl'] .client-details__header-link{
    font-family: 'CairoSemiBold';
}
.client-details__info-receipts-title{
    font-size: 19.03px;
    color:#646464;
}
.client-details__info-receipts-imgs-wrapper{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:14px;
}
.client-details__info-receipts-img-wrapper{
    width: 100%;
    position: relative;
}
.client-details__info-receipts-img{
    width: 100%;
    /* height: 100%; */
    height: 154px;
    object-fit: cover;
}
.client-details__info-receipts-total-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    opacity: 0.65;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), lightgray 50% / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: white;
    font-family: 'CairoBold';
}
.client-details__info-receipts-total-wrapper:hover{
    color: white;
}
.client-details__info-wrapper{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    gap:32px;
}
.client-details__info-item-wrapper{
    display:flex;
    flex-direction: column;
    gap:5px
}
.client-details__info-title{
    font-size: 19.03px;
    color:#646464;
}
.client-details__info-value{
    font-size: 20px;
    color:#000;
    font-family: 'InterMedium';
}
body[dir='rtl'] .client-details__info-value{
    font-family: 'CairoMedium';
}
.client-details__info-link{
    font-size: 20px;
    color:#0d6efd;
    font-family: 'InterMedium';
    width: fit-content;
}
body[dir='rtl'] .client-details__info-link{
    font-family: 'CairoMedium';
}
.client-details__info-button{
    width: 159px;
    height: 76px;
    border-radius: 10px;
    border: 1.5px solid #D9D9D9!important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-details__table-header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    margin-bottom: 20px;
}
.client-details__table-title{
    color: #000;
    font-size: 29px;
    font-family: 'InterBold';
}
body[dir='rtl'] .client-details__table-title{
    font-family: 'CairoBold';
}
.client-details__table-link{
    color: #5885C3;
    border-bottom: 1px solid #AAAAAA;
    font-family: 'InterSemiBold';
    padding-bottom: 7px;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap:5px;
    margin-left: auto;
}
body[dir='rtl'] .client-details__table-link{
    font-family: 'CairoSemiBold';
    margin-left: initial;
    margin-right: auto;
}
.client-details__table-link-icon{
    width: 20px;
    height: 20px;
}

@media (max-width:768px) {
    .client-details__header-wrapper{
        gap: 5px;
        margin-bottom: 30px;
        flex-direction: column;
    }
    .client-details__info-wrapper{
        display:grid;
        grid-template-columns: repeat(2,1fr);
        gap:32px;
    }
    .client-details__info-button{
        width: 120px;
        height: 45px;
        border-radius: 6px;
    }
    .client-details__info-button svg{
        width: 80px;
    }
    .client-details__table-header-wrapper{
        padding: 0 0px;
    }
    .client-details__header-link{
        border-bottom: 1px solid #AAAAAA!important;
        padding-bottom: 5px;
        font-size: 23px;
        gap: 10px;
    }
    .client-details__header-link-icon{
        width: 20px;
        height: 20px;
    }
}
@media (max-width:450px) {
    .client-details__header-link{
        font-size: 18px;
        gap: 5px;
    }
    .client-details__info-wrapper {
        grid-template-columns: repeat(1,1fr);
        gap: 10px;
    }
}