.client-details{
    background: url('/src/assets/imgs/loginBG.png') left no-repeat;
    background-size: contain;
}
.client-details__header-wrapper{
    display:flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 64px; */
    margin-bottom: 40px;

}
.client-details__header-title{
    font-size:46.722px;
    font-family: 'InterBold';
}
body[dir='rtl'] .client-details__header-title{
    font-family: 'CairoBold';
}
.page-header__link{
    /* text-decoration: underline; */
    color: #5885C3;
    border-bottom: 2px solid #AAAAAA;
    font-family: 'InterSemiBold';
    padding-bottom: 16px;
    font-size: 30px;
    display: flex;
    align-items: center;
    gap:22px;
    /* margin-left: auto; */
}
body[dir='rtl'] .page-header__link{
    font-family: 'CairoSemiBold';
}

.client-details__header-link{
    color: #5885C3;
    border-bottom: 2px solid #AAAAAA!important;
    font-family: 'InterSemiBold';
    padding-bottom: 8px;
    font-size: 30px;
    display: flex;
    align-items: center;
    gap:15px;
}
body[dir='rtl'] .client-details__header-link{
    font-family: 'CairoSemiBold';
}
.client-details__header-link-icon{

}

.client-details__info-receipts-wrapper{
    
}
.client-details__info-receipts-title{
    font-size: 19.03px;
    color:#646464;
}
.client-details__info-receipts-imgs-wrapper{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:30px;
}
.client-details__info-receipts-img{
    width: 100%;
    height: 100%;
    border-radius: 25px;
}
.client-details__info-receipts-total-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    opacity: 0.65;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), lightgray 50% / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: white;
    font-family: 'CairoBold';
}
.client-details__info-receipts-total-wrapper:hover{
    color: white;
}
.client-details__info-wrapper{
    display:grid;
    grid-template-columns: repeat(2,1fr);
    gap:32px;
}
.client-details__info-item-wrapper{
    display:flex;
    flex-direction: column;
    gap:5px
}
.client-details__info-title{
    font-size: 19.03px;
    color:#646464;
}
.client-details__info-value{
    font-size: 20px;
    color:#000;
    font-family: 'InterMedium';
}
body[dir='rtl'] .client-details__info-value{
    font-family: 'CairoMedium';
}
.client-details__info-link{
    font-size: 20px;
    font-family: 'InterMedium';
    color:#0d6efd;
    width: fit-content;
}
body[dir='rtl'] .client-details__info-link{
    font-family: 'CairoMedium';
}

.client-details__info-value--input{
    font-size: 20px;
    color:#505050;
    /* font-family: 'InterMedium'; */
    border: 2px solid #D9D9D9;
    padding:14px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    background-color: white;
}
body[dir='rtl'] .client-details__info-value--input{
    /* font-family: 'CairoMedium'; */
}
.client-details__info-button{
    width: 159px;
    height: 76px;
    border-radius: 10px;
    border: 1.5px solid #D9D9D9!important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-details__table-wrapper{

}
.client-details__table-header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    margin-bottom: 20px;
}
.client-details__table-title{
    color: #000;
    font-size: 29px;
    font-family: 'InterBold';
}
body[dir='rtl'] .client-details__table-title{
    font-family: 'CairoBold';
}
.client-details__table-link{
    color: #5885C3;
    border-bottom: 1px solid #AAAAAA;
    font-family: 'InterSemiBold';
    padding-bottom: 7px;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap:5px;
    margin-left: auto;
}
body[dir='rtl'] .client-details__table-link{
    font-family: 'CairoSemiBold';
    margin-left: initial;
    margin-right: auto;
}
.client-details__table-link-icon{
    width: 20px;
    height: 20px;
}

.form__content-form-input{
    width: 100%;
    border-radius: 10px;
    background-color: white;
    color:#A0A0A0;
    border: 1.5px solid #D9D9D9!important;
    padding:10px;
    font-family: 'InterRegular';
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.form__content-form-input:focus{
    outline: 1px solid #d0c7c7;
}
.form__content-form-input-icon{
    width: 60px;
    display: flex;
    margin: auto;
    height: 24px;
}

.form__content-form-input-google{
    width: 120px;
    border-radius: 10px;
    background-color: white;
    color:#A0A0A0;
    border: 1.5px solid #D9D9D9!important;
    padding:10px;
    font-family: 'InterRegular';
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.form__content-form-input:focus{
    outline: 1px solid #d0c7c7;
}
.form__content-form-input-icon{
    width: 60px;
    display: flex;
    margin: auto;
    height: 24px;
}
@media (max-width:991px) {
    .client-details__info-receipts-imgs-wrapper{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width:768px) {
    
    .page-header__link{
        border-bottom: 2px solid #AAAAAA;
        padding-bottom: 0px;
        font-size: 20px;
        gap: 4px;
    }
    .page-header__link svg{
        width: 30px;
    }
}
@media (max-width:500px) {
    .page-header__link{
        margin-left: initial!important;
        margin-right: initial!important;
    }
    .client-details__info-receipts-imgs-wrapper{
        display:grid;
        grid-template-columns: 1fr;
        gap:30px;
    }
    .client-details__header-wrapper{
        margin-bottom: 20px;
    }
    .client-details__header-title{
        font-size:35px;
    }
    .client-details__info-wrapper {
        grid-template-columns: repeat(1,1fr);
        gap: 12px;
    }
}