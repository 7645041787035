:root{
  --main-color:#5885C3;
  --second-color:#4AC1EB;
  --gradient-color:radial-gradient(114.12% 154.55% at 16.28% 88.57%, #4AC1EB 33.85%, #00FFFF 100%) ;
  --gradient-color-animation:radial-gradient(114.12% 154.55% at 16.28% 88.57%, #00FFFF 33.85%, #4AC1EB 100%) ;
  --transition:all .2s linear;
  --main-background-color:#F5F5F5;
  --text-color-dark:#121212;
}
@font-face {
  font-family: InterRegular;
  src: url(assets/fonts/Inter/Inter-Regular.ttf);
}
@font-face {
  font-family: InterSemiBold;
  src: url(assets/fonts/Inter/Inter-SemiBold.ttf);
}
@font-face {
  font-family: InterBold;
  src: url(assets/fonts/Inter/Inter-Bold.ttf);
}
@font-face {
  font-family: InterExtraBold;
  src: url(assets/fonts/Inter/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: InterMedium;
  src: url(assets/fonts/Inter/Inter-Medium.ttf);
}
@font-face {
  font-family: InterLight;
  src: url(assets/fonts/Inter/Inter-Light.ttf);
}

@font-face {
  font-family: CairoBold;
  src: url(assets/fonts/Cairo/Cairo-Bold.ttf);
}
@font-face {
  font-family: CairoSemiBold;
  src: url(assets/fonts/Cairo/Cairo-SemiBold.ttf);
}
@font-face {
  font-family: CairoRegular;
  src: url(assets/fonts/Cairo/Cairo-Regular.ttf);
}
@font-face {
  font-family: CairoMedium;
  src: url(assets/fonts/Cairo/Cairo-Medium.ttf);
}
htmL{
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body{
  position: relative;
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
  margin: 0;
  padding: 0;
  background: url('/src/assets/imgs/loginBG.png') left no-repeat;
  background-size: contain;
  background-color:#F5F5F5;
  /* background-color:#F4F7FA; */
  font-family:'InterRegular';
  height:100vh;
}
body[dir='rtl'] {
  font-family:'CairoRegular';
}
@media (max-width:765px) {
  body{
    /* background-color: white; */
  }
}

body[dir='rtl'] input{
  font-family:'CairoRegular';
}
button{
  border: 0 !important;
  outline: 0 !important;
  background-color: transparent;
}
ul{
  list-style-type:none;
  margin:0;
  padding:0;
}
a{
  text-decoration:none;
}
p{
  margin: 0;
}
::-webkit-scrollbar{
  width: 14px;
}
::-webkit-scrollbar-track{
  background: #dde1e5; 
  overflow:hidden
}
::-webkit-scrollbar-thumb{
  /* background: linear-gradient(0deg, rgb(250, 251, 191) 12%, rgba(182,138,53,1) 77%); */
  background-color: var(--main-color);
}
::-webkit-scrollbar-thumb:hover{
  /* background: linear-gradient(0deg, rgb(250, 251, 191) 20%, rgba(182,138,53,1) 77%); */
  background-color: var(--main-color);
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */
.form-control ,.form-control:focus ,.form-control:focus-visible ,.form-select:focus{
  box-shadow: none;
}
.form-select:focus{
  border-color: black;
}
.not-allowed{
  cursor:not-allowed!important;
  pointer-events: initial!important; 
}
.custom-modal .modal-content{
    padding: 60px 40px 30px;
}
@media (max-width:500px) {
  .custom-modal .modal-content{
    padding: 30px;
  }
}
body[dir='rtl'] select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 1%;
  background-position-y: 50%;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
}
.app{
  overflow-x: hidden;
} 
.swiper{
  direction: ltr;
}
.loading-image-div{
  height: 100%;
  background-color: gray;
}
.lazy-load-image-background{
  height: 100%;
  background-color:#ded9d9  ;
}
[data-aos="new-animation"]::after {
  width:10%;
  transition:var(--transition);
  transition-duration: .8s!important;
  transition-property: transform, opacity;
}
[data-aos="new-animation"].aos-animate::after {
  width: 100%;
}
.react-datepicker__close-icon{
  padding: 0 15px 0 0!important;
}
.react-datepicker__close-icon::after {
  background-color: var(--main-color)!important;
  height: 25px!important;
  width: 25px!important;
  font-size: 20px!important;
  font-family: 'InterBold';
  display: flex!important;
  align-items: center;
  justify-content: center;
}
  /* @media screen and (min-width: 768px) {
    [data-aos="new-animation"]::before{

      transform: translateX(100px);
    }

    [data-aos="new-animation"].aos-animate::before {
      transform: translateX(0);
    }
  } */
@media (min-width: 1400px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
      max-width: 1350px;
  }
  .container--header{
    max-width: 1420px;
  }
}

.lazy-load-image-background.lazy-load-image-loaded .loading-img{
  z-index: 2;
}
.lazy-load-image-background.lazy-load-image-loaded ~.loading-div{
  background-color: transparent;
}
.lazy-load-image-background{
  width: 100%;
  border-radius: 44.75px;
}
.loading-img{
  z-index: -1;
  display: flex;
  position: relative;
}
.loading-div{
  background-color:#EDEDED;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  z-index: 1;
  border-radius:44.75px
}