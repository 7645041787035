.all-admins-wrapper{
    position: relative;
}
.all-admins-wrapper__loading-icon-wrapper{
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffffba;
}
.all-admins__item-wrapper:nth-of-type(even){
    background-color: #E1E1E1E5;
}
.all-admins__item-wrapper:nth-of-type(even) .all-admins__item-info-name{
    color:black
}
.all-admins__item{
    display: flex;
    align-items: center;
    gap:25px;
    padding:25px;
}

.all-admins__item-img{
    width: 130px;
    height: 130px;
    border-radius: 50%;
}
.all-admins__item-img-div{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color:#D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'CairoBold';
    font-size: 60px;
    color:black
}
.all-admins__item-info-wrapper{
    display: flex;
    flex-direction: column;
    gap:10px
}
.all-admins__item-info-name{
    font-size: 36.102px;
    color:#646464;
    font-family: 'CairoBold';
}
.all-admins__item-info-role{
    font-size: 27.076px;
    color:#5A5A5A;
    font-family: 'CairoBold';
}
.all-admins__item-link-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    gap:20px
}
body[dir='rtl'] .all-admins__item-link-wrapper{
    margin-left: initial;
    margin-right: auto;
}
.all-admins__item-link{
    display: flex;
    gap:5px;
    color:#5885C3;
    font-size: 20.381px;
    font-family: 'InterMedium';
}
body[dir='rtl'] .all-admins__item-link{
    font-family: 'CairoMedium';
}
.all-admins__item-delete-link{
    display: flex;
    gap:5px;
    color:#c35858;
    font-size: 20.381px;
    font-family: 'InterMedium';
}
.all-admins__item-delete-link .all-admins__item-link-icon path{
    /* fill:#c35858; */
}
body[dir='rtl'] .all-admins__item-link{
    font-family: 'CairoMedium';
}
.all-admins__item-link-icon{
    width:20px
}
@media (max-width:768px){
    .all-admins__item {
        gap: 5px;
        padding: 10px;
    }
    .all-admins__item-img {
        width: 60px;
        height: 60px;
    }
    .all-admins__item-info-wrapper {
        gap: 0px;
    }
    .all-admins__item-info-name {
        font-size: 20px;
    }
    .all-admins__item-info-role {
        font-size: 16px;
    }
    .all-admins__item-link {
        gap: 2px;
        font-size: 16px;
    }
}