.language__items-wrapper{
    display:flex;
    align-items:center;
    gap:16px;
}
.language__items{
    font-size:16px;
    color:var(--main-color);
    background-color: transparent;
    font-family: 'InterBold';
}
.language__items-light{
    color:white;
}
body[dir='rtl'] .language__items{
    font-family: 'CairoBold';
}
.language__items--active{
    border-bottom: 1px solid white!important;
}
