.navbar{
    padding:40px 20px 40px 20px;
    display:flex;
    flex-direction: column;
    background-color: white;
    /* margin-bottom: 35px; */
    width:100%;
    height: 100%;
    min-height: 100vh;
    /* border-radius:40px; */
    transition: var(--transition);
}
.navbar__nav{
    width:100%;
}
.navbar__nav-logo{
    width: 150px;
    margin: auto;
    display: flex;
    padding-right: 20px;
}
.navbar__nav-list{
    display: flex;
    flex-direction: column;
    gap:25px;
    width: 100%;
}
.navbar__nav-link-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 4px;
    color:#828282;
    font-size: 18px;
    transition:var(--transition);
    padding: 15px 25px;
    /* padding-right: 0; */
    border-radius: 50px 50px;
    width:fit-content;
    margin: auto;
    text-align: center;
}
.navbar__nav-link-wrapper:hover{
    color:var(--main-color)
}
.navbar__nav-link-icon{
    width: 18px;
    height: 18px;
    transition: var(--transition);
}
.navbar__nav-link-wrapper:hover .navbar__nav-link-icon path{
    stroke:var(--main-color)
}
.navbar__nav-link-wrapper:hover .navbar__nav-link-icon rect{
    fill:var(--main-color)
}
.navbar__nav-link-wrapper rect{
    transition:var(--transition);
}
.navbar__nav-link-logout{
    width:174px;
    margin:auto;
    height:52px;
    /* background-color: #30A7DB; */
    background-color: var(--main-color);
    color:white;
    font-size: 18px;
    display:flex;
    gap:5px;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    transition: var(--transition);
    margin-top: 100px;
}
.navbar__nav-link-logout:hover{
    opacity: .8;
    /* color:var(--main-color); */
}
.sidebar__client-info-wrapper{
    display:flex;
    align-items: center;
    gap:11px;
    margin-bottom: 0px;
}
.navbar__nav-language{
    display:flex;
    align-items:center;
    justify-content:center
}
.sidebar__client-info-img{
    width: 68px;
    height: 68px;
    border-radius: 50%;
}
.sidebar__client-info-content-wrapper{
    display: flex;
    flex-direction: column;
    gap:5px
}
.sidebar__client-info-name{
    font-size: 22px;
    color:#1E1E1E;
    font-family: 'CairoBold';
}
.sidebar__client-info-text{
    font-size: 14.76px;
    color:#646464;
    font-family: 'CairoMedium';
}
.navbar__nav-item--absolute{
    position: absolute;
    top:60px
}
.navbar__nav-item--responsive{
    display: none;
}
@media (max-width:765px) {
    .navbar{
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 10000;
        transform: translateX(-100%);
        border-radius: 0;
        overflow-y: auto;
    }
    .navbar--open{
        transform: translateX(0%);
    }
    .sidebar__client-info-wrapper{
        display:flex;
    }
    .navbar__nav-item--responsive{
        display: flex;
    }
}