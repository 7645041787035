
.form__content-wrapper{
    /* padding:58px 112px; */
    /* background-color:white; */
    /* border-radius: 30px; */
}
.form__content-title{
    font-size: 46.72px;
    margin-bottom: 22px;
    font-family: 'InterBold';
}
body[dir='rtl'] .form__content-title{
    font-family: 'CairoBold';
}
.form__content-form{
    margin-bottom: 20px;
}
.form__content-form-inputs-wrapper{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-bottom: 20px;
}
.form__content-form-input{
    width: 100%;
    border-radius: 10px;
    background-color: white;
    color:#A0A0A0;
    border: 1.5px solid #D9D9D9!important;
    padding:15px;
    font-size: 17px;
    font-family: 'InterRegular';
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.form__content-form-input:focus{
    outline: 1px solid #d0c7c7;
}
.form__content-form-input-wrapper{
    position: relative;
    width:30%
}
.form__content-form-input-wrapper .form__content-form-input{
    width:100%
}
.form__content-form-input-icon{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.form__content-form-submit{
    font-size: 24px;
    color:white;
    background-color: var(--main-color);
    font-family: 'InterBold';
    width:384px;
    height:76px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 10px;
    transition: var(--transition);
}

.form__content-form-submit:hover{
    opacity: .8;
}
body[dir='rtl'] .form__content-form-submit{
    font-family: 'CairoBold';
}