.error{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.error__img{
    display:flex;
    margin: auto;
    max-width: 600px;
    transition: var(--transition);
    animation: imageAnimation 1.5s infinite alternate linear;
    /* margin-top: 200px; */
    /* margin-bottom: 30px; */
}
@keyframes imageAnimation {
    from{
        transform: translateY(0px);
    }
    to{
        transform: translateY(20px);
    }
}
.error__content-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.error__content-title{
    font-size: 146.5px;
    font-family: 'InterSemiBold';
    color:#646464;
    line-height: 140px;
}
.error__content-second-title{
    font-size: 86.5px;
    font-family: 'InterSemiBold';
    /* color:#F5B453; */
    color:var(--main-color);
    margin-bottom: 10px;
}
.error__content-description{
    font-size: 26.5px;
    font-family: 'InterSemiBold';
    color:#828282;
    margin-bottom: 20px;
}
.error__content-link{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    /* background: var(--gradient-color); */
    background: var(--main-color);
    font-size: 20.06px;
    font-family: 'InterSemiBold';
    width: 250px;
    height: 70px;
    border-radius: 50px;
    transition: var(--transition);
}
.error__content-link:hover{
    color:white!important;
    opacity:.8;
}
@media (max-width:991px) {
    .error{
        margin-top: 20px;
    }
    .error__img{
        max-width: 250px;
    }
    .error__content-wrapper{
        align-items: center;
    }
    .error__content-title{
        font-size: 80px;
        margin: 0;
        line-height: initial;
        font-family: 'InterBold';
    }
    .error__content-second-title{
        font-size: 46.5px;
        font-family: 'InterSemiBold';
        margin-bottom: 10px;
    }
    .error__content-description{
        font-size: 20px;
        font-family: 'InterMedium';
        margin-bottom: 12px;
        text-align: center;
    }
    .error__content-link{
        font-size: 17.06px;
        font-family: 'InterSemiBold';
        width: 200px;
        height: 40px;
        border-radius: 50px;
    }
}