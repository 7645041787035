.page-wrapper{
    background: url('/src/assets/imgs/loginBG.png') left no-repeat;
    background-size: contain;
}
.page-header{
    display:flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content:space-between;
    flex-wrap: wrap; */
    gap:20px;
    margin-bottom: 50px;
}
.page-header__top-wrapper{
    display:flex;
    align-items: center;
    justify-content:space-between;
    flex-wrap: wrap;
    gap:20px
}
.page-header__title{
    font-size:46.72px;
    color:black;
    font-family: 'InterBold';
    display: flex;
    align-items: center;
    gap:18px;
}
body[dir='rtl'] .page-header__title{
    font-family: 'CairoBold';
}
.page-header__sub-title{
    font-size:29px;
    color:black;
    font-family: 'InterBold';
}
body[dir='rtl'] .page-header__sub-title{
    font-family: 'CairoBold';
}
.page-header__title-count{
    color: #5A5A5A;
    font-family: 'InterMedium';
    font-size: 31.58px;
}

.form__content-form-input{
    width: 100px;
    border-radius: 7px;
    background-color: white;
    color:#A0A0A0;
    border: 1.5px solid #D9D9D9!important;
    padding: 10px;
    font-size: 19.03px;
    font-family: 'InterRegular';
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.form__content-form-input:focus{
    outline: 1px solid #d0c7c7;
}
.page-header__search-inputs-wrapper{
    display:flex;
    align-items: center;
    justify-content: center;
    gap:10px
}
.page-header__search-input{
    width: 400px;
    border-radius: 10px;
    background-color: white;
    color:#A0A0A0;
    border: 1.5px solid #D9D9D9!important;
    padding:20px;
    font-size: 19.03px;
    font-family: 'InterRegular';
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.page-header__search-input:focus{
    outline: 1px solid #d0c7c7;
}
.page-header__search-submit{
    background-color: white;
    width:170px;
    border: 2.5px solid #5885C3!important;
    color:var(--main-color);
    font-family: 'InterBold';
    height: 76px;
    border-radius: 15px;
}
body[dir='rtl'] .page-header__search-submit{
    font-family: 'CairoBold';
}
.page-header__link{
    /* text-decoration: underline; */
    color: #5885C3;
    border-bottom: 2px solid #AAAAAA;
    font-family: 'InterSemiBold';
    padding-bottom: 16px;
    font-size: 30px;
    display: flex;
    align-items: center;
    gap:22px;
    /* margin-left: auto; */
}
body[dir='rtl'] .page-header__link{
    font-family: 'CairoSemiBold';
    margin-left: initial;
    margin-right: auto;
}

.page-header__pages-link-wrapper{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    width: 70%;
    margin: auto;
    gap:15px
}
.page-header__pages-link{
    background-color: white;
    padding:15px 20px;
    border: 1px solid #A0A0A0!important;
    color:#A0A0A0;
    font-family: 'InterBold';
    border-radius: 10px;
    transition: var(--transition);
}
.page-header__pages-link--active{
    background-color: var(--main-color);
    color: white;
    border-width: 0px;
}