.client-details{
    background: url('/src/assets/imgs/loginBG.png') left no-repeat;
    background-size: contain;
}
.client-details__header-wrapper{
    display:flex;
    align-items: center;
    justify-content:flex-end;
    gap: 64px;
    margin-bottom: 40px;
}
.client-details__receipt-img{
    width:80%;
    height: 65vh;
    margin: auto;
    object-fit: contain;
}