.navbar{
    padding-top:80px;
    display:flex;
    /* flex-direction: column; */
    /* background-color: white; */
    margin-bottom: 35px;
    /* width:100%; */
    /* height: 95%; */
    border-radius:40px;
    transition: var(--transition);
}
/* .navbar__nav{
    width:100%;
} */
.navbar__nav-logo{
    width: 150px;
    margin: auto;
    display: flex;
    padding-right: 20px;
}
.navbar__nav-list{
    display: flex;
    gap:25px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.navbar__nav-link-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 4px;
    color:#464646;
    font-size: 19px;
    transition:var(--transition);
    padding: 0;
    padding-right: 0;
    border-radius: 50px 0 0 50px;
}
.navbar__nav-link-wrapper:hover{
    color:var(--main-color)
}
.navbar__nav-link-wrapper--logout{
    color:red;
    font-family: 'CairoBold';
    width:100%;
}
.navbar__nav-link-icon{
    width: 18px;
    height: 18px;
    transition: var(--transition);
}
.navbar__nav-link-wrapper:hover .navbar__nav-link-icon path{
    stroke:var(--main-color)
}
.navbar__nav-link-wrapper:hover .navbar__nav-link-icon rect{
    fill:var(--main-color)
}
.navbar__nav-link-wrapper rect{
    transition:var(--transition);
}
.navbar__nav-link-logout{
    width:174px;
    margin:auto;
    height:52px;
    background-color: #30A7DB;
    color:white;
    font-size: 18px;
    display:flex;
    gap:5px;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    transition: var(--transition);
}
.navbar__nav-link-logout:hover{
    opacity: .8;
    color:white
}

.navbar__nav-item-profile-list-wrapper{
    position: relative;
}
.navbar__nav-item-profile-list-wrapper:hover .navbar__nav-item-profile-list {
    transform:translateY(0);
    opacity:1;
    z-index:1;
}

.navbar__nav-item-profile-list{
    width:200px;
    height:fit-content;
    /* padding: 10px; */
    background-color: white;
    border-radius:10px;
    position:absolute;
    top:100%;
    right:0%;
    transition: var(--transition);
    transform:translateY(20px);
    opacity:0;
    z-index:-1;
}
body[dir='rtl'] .navbar__nav-item-profile-list{
    right:initial;
    left:0%;
}
.navbar__nav-item-profile-item{
    padding:8px 12px;
    transition:var(--transition)
}
.navbar__nav-item-profile-item:hover{
    background-color: #ececfa;
}
.navbar__nav-item-profile-item--fixed-color{
    background-color: #ececfa;
}
.sidebar__client-info-wrapper{
    display:flex;
    align-items: center;
    gap:11px;
}
.sidebar__client-info-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.sidebar__client-info-content-wrapper{
    display: flex;
    flex-direction: column;
    /* gap:5px */
}
.sidebar__client-info-name{
    font-size: 16px;
    color:black;
    font-family: 'InterBold';
}
body[dir='rtl'] .sidebar__client-info-name{
    font-family: 'CairoBold';
}
.sidebar__client-info-text{
    font-size: 12px;
    color:#646464;
    font-family: 'CairoMedium';
    text-transform: capitalize;
}
/* .navbar__nav-item--absolute{
    position: absolute;
    top:60px
} */
.navbar__nav-item--responsive{
    display: none;
}
.navbar__nav-item--margin{
    margin-left: auto;
}
body[dir='rtl'] .navbar__nav-item--margin{
    margin-left: initial;
    margin-right: auto;
}
@media (max-width:991px) {
    .navbar{
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 10000;
        transform: translateX(-100%);
        border-radius: 0;
        overflow-y: auto;
    }
    .navbar--open{
        transform: translateX(0%);
    }
    .sidebar__client-info-wrapper{
        display:flex;
    }
    .navbar__nav-item--responsive{
        display: flex;
    }
}