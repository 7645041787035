
reach-portal{
    color: black!important;
    z-index: 10000000000000000!important;
}
.comboo{
    z-index: 10000000000000000!important;
    
}
[data-reach-combobox-popover]{
    color: black!important;
    z-index: 10000000000000000!important;
}
[data-reach-combobox-list]{
    -webkit-user-select: initial!important; 
    user-select: initial!important;
}
.map-modal__search-input{
    position: absolute;
    z-index: 1000;
    width: 500px;
    right: 60px;
    top: 7px;
    color: #A0A0A0;
    border: 1.5px solid #D9D9D9;
    border-radius: 10px;
    outline:none;
    font-size: 19.03px;
    padding:12px 20px
}
@media (max-width:991px){
    .map-modal__search-input{
        position: initial;
        max-width: 100%;
        margin-bottom: 10px;
    }
}