.navbar__nav-listt a.active{
    background-color: var(--main-color);
    color: white !important;
}
.navbar__nav-listt a.active path{
    stroke:var(--main-color)
}
.navbar__nav-listt a.active rect{
    fill:var(--main-color)
}
/* .navbar__nav-list a.active::before{
    content:'';
    position:absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    width:20px;
    height: 20px;
    background-color: green;
} */