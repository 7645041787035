.navbar__nav-list a.active{
    font-family: 'InterBold';
    color: black!important;
}
body[dir='rtl'] .navbar__nav-list a.active{
    font-family: 'CairoBold';
    color: black!important;
}

.navbar__nav-list a.active path{
    stroke:var(--main-color)
}
.navbar__nav-list a.active rect{
    fill:var(--main-color)
}
/* .navbar__nav-list a.active::before{
    content:'';
    position:absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    width:20px;
    height: 20px;
    background-color: green;
} */