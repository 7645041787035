.all-admins__item-wrapper:nth-of-type(even){
    /* background-color: #E1E1E1E5; */
}
.all-admins__item{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    transition:var(--transition)
}
.all-admins__item:hover{
    transform: translateX(15px);
}

.all-admins__item-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.all-admins__item-img-div{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color:#D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'CairoBold';
    font-size: 20px;
    color:black
}
.all-admins__item-info-wrapper{
    display: flex;
    flex-direction: column;
}
.all-admins__item-info-name{
    font-size: 16px;
    color:#646464;
    font-family: 'CairoBold';
}
.all-admins__item-info-role{
    font-size: 14px;
    color:#5A5A5A;
    font-family: 'CairoBold';
}
.all-admins__item-link{
    margin-left: auto;
    display: flex;
    gap:5px;
    color:#5885C3;
    font-size: 20.381px;
    font-family: 'InterMedium';
}
body[dir='rtl'] .all-admins__item-link{
    margin-left: initial;
    margin-right: auto;
    font-family: 'CairoMedium';
}