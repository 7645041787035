.client-details-receipts .swiper-pagination-bullet{
    width: 23.22px;
    height: 23.22px;
    background: #d2cef4!important;
}
.client-details-receipts .swiper-pagination-bullet-active{
    background: #5885C3!important;
}
.client-details-receipts .swiper-pagination{
    position: relative;
    margin-top: 20px;
}
.thumbnailClass img{
    height:100px;
    object-fit: cover;
}