
.sidebar{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10000000000;
    display: flex;
    width: 0;
    transition: var(--transition);
    overflow: hidden;
    height: 0;
    border-radius: 50%;
    border-top-left-radius: 0;
}
.sidebar--open{
    height: 100%;
    width: 100%;
    border-radius: 0;width: 100%;
}
.sidebar__logo{
    width: 87px;
    height: 25px;
    object-fit: contain;

}
.sidebar__close-button{
    background-color: transparent;
    font-size: 22px;
    color: white;
    font-family: 'InterBold';
}
.sidebar__content{
    width: 70%;
    background-color: var(--main-color);
    height: 100%;
    padding: 30px 25px;
    overflow-y: auto;
}
.sidebar__profile-cont{
    padding: 0px 30px;
    border-bottom: 1px solid #0000001F;
}
.sidebar__overlayer{
    width: 30%;
    background-color: black;
    height: 100%;
    opacity: .8;
}
.sidebar__profile-img{
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    border: 1px solid var(--text-color-dark);
    border-radius: 50%;
    padding: 6px;
    object-fit: contain;
}
.sidebar__profile-name{
    color: var(--text-color-dark);
    font-family: 'CairoMedium';
}
.sidebar__nav-item{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;
    padding: 16px 0px;
    font-size: 25px;
    font-family: 'CairoMedium';
    justify-content: center;
}
.sidebar__nav-item a{
    /* width: 100%; */
    /* padding: 20px 40px; */
    display:flex;
    align-items: center;
    transition:var(--transition);
    color: white;
}
.sidebar-menu-link{
    background-color:transparent;
    color: white;
}
.sidebar__nav-item:hover .sidebar__nav-link{
    color: white;
}
.sidebar__nav-item a.active{
    /* background: linear-gradient(270deg, rgb(250, 251, 191) 12%, rgba(182,138,53,1) 77%); */
    background: var(--main-color);
}
.sidebar__nav-item a.active{
    color: white;
}
.sidebar__nav-list{
    border-top: 1px solid #0000001F;
    margin-bottom: 100px;
}
.sidebar__nav-list .sidebar__nav-item:last-of-type{
    border-bottom: 1px solid #0000001F;
}
.sidebar__nav-link{
    color:var(--text-color-dark);
    transition: var(--transition);
}
.sidebar__logOut-cont{
    padding:30px;
}
.sidebar__logOut{
    background-color: transparent;
    border: 0;
    color: #CE2424;
    margin: auto;
    cursor: pointer;
}
.sidebar__logOut-icon{
    margin-right: 5px;
}
.sidebar__content::-webkit-scrollbar{
    width: 8px;
}

.sidebar__contact-item{
    background-color:var(--main-color);
    display: flex;
    align-items:center;
    border-radius: 5px;
    justify-content: flex-start;
    padding: 0;
    /* height: 47px; */
    /* width: 270px; */
    direction: ltr;
    gap: 10px;
}
.sidebar__contact-text{
    color: white;
    font-size: 13px;
    /* margin-left: 10px; */
}
/* body[dir='rtl'] .sidebar__contact-text{
    margin-left: 0px;
    margin-right: 10px;
} */
.sidebar__language-span{
    font-size: 19.17px;
    margin-bottom: 16px;
    font-family: 'InterBold';
    color: white;
    margin-top: 20px;
}
.sidebar__nav-link-icon--rotate{
    transform: rotate(270deg);
}
body[dir='rtl'] .sidebar__nav-link-icon--rotate{
    transform: rotate(-270deg);
}
body[dir='rtl'] .sidebar__close-icon{
    transform: rotate(180deg);
}
.sidebar__content-language{
    --btn-bg-color:white;
    --btn-color:var(--main-color);
    /* margin: auto; */
}
.sidebar__logo{
    height: 59px;
    width: 200px;
    /* width: -moz-fit-content; */
    /* width: fit-content; */
    object-fit: contain;
    

}

.footer__social-icons-wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px 35px;
    width: fit-content;
}
.footer__social-icon-wrapper{
    width: 50px;
    height: 50px;
    background-color: #FFFFFF;
    padding: 10px, 6px, 10px, 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer__social-icon{
    width: fit-content;
    height: fit-content;
    padding-bottom: 10px;
    border-bottom:2px solid black
}

.contact-us__social-wrapper{
    display: flex;
    align-items: center;
    color: #2F2F2F!important;
    background-color: transparent;
    margin-bottom:50px;
    width: 100%;
    padding: 0;
}
.contact-us__social-icon-wrapper ,.contact-us__social-icon-wrapper--responsive{
    min-width: 71.58px;
    height: 71.58px;
    /* background-color:white; */
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content:center
}
body[dir='rtl'] .contact-us__social-icon-wrapper{
    margin-right: 0px;
    margin-left: 20px;
}
body[dir='rtl'] .contact-us__social-icon-wrapper--responsive{
    margin-right: 0px;
    margin-left: 20px;
}
.contact-us__social-icon-wrapper--responsive{
    display: none;
}
@media(min-width:1200px){
    .sidebar{
        width:0!important;
    }
}

.sidebar__client-info-wrapper{
    display:flex;
    align-items: center;
    gap:11px;
}
.sidebar__client-info-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.sidebar__client-info-content-wrapper{
    display: flex;
    flex-direction: column;
    /* gap:5px */
}
.sidebar__client-info-name{
    font-size: 16px;
    color:white;
    font-family: 'InterBold';
}
body[dir='rtl'] .sidebar__client-info-name{
    font-family: 'CairoBold';
}
.sidebar__client-info-text{
    font-size: 12px;
    color:white;
    font-family: 'CairoMedium';
}
.navbar__nav-link-logout{
    width:174px;
    margin:auto;
    height:52px;
    background-color: #30A7DB;
    color:white;
    font-size: 18px;
    display:flex;
    gap:5px;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    transition: var(--transition);
}
.navbar__nav-link-logout:hover{
    opacity: .8;
    color:white
}
/***************brand*************************/
.sidebar__brand{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color:white;
    padding: 25px;
    transition: var(--transition);
    transform: translateX(100%);
}
.sidebar__brand-back-wrapper{
    background-color: transparent;
}
.sidebar__brand-title{
    font-size: 20px;
    color: #313131;
    margin-bottom:14px;
    font-family: 'InterBold';
    text-align: center;
}
.sidebar__brand-items-wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 14px;
}
.sidebar__brand-item-wrapper{
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 0 10px;
    width: 100%;
}
.sidebar__brand-item-wrapper--type{
    background-color: transparent;
}
.sidebar__brand-item-icon{
    margin-bottom: 8px;
    width: 100%;
}
.sidebar__brand-item-text{
    font-size: 15.51px;
    color: #141414;
    font-family: 'InterSemiBold';
    text-transform: capitalize;
}
.sidebar__brand--open{
    transform: translateX(0%);
}
@media (max-width:500px) {
    .sidebar__brand-items-wrapper{
        grid-template-columns: repeat(2,1fr);
    }
    .sidebar__overlayer{
        width: 15%;
    }
    .sidebar__content{
        width: 85%;
    }
}