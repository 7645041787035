.page-wrapper{
    background: url('/src/assets/imgs/loginBG.png') left no-repeat;
    background-size: contain;
}
.page-header{
    display:flex;
    align-items: center;
    justify-content:space-between;
    margin-bottom: 50px;
}
.page-header__title{
    font-size:46.72px;
    color:black;
    font-family: 'InterBold';
    /* display: flex;
    align-items: center; */
    gap:18px;
}
body[dir='rtl'] .page-header__title{
    font-family: 'CairoBold';
}

.page-header__title-count{
    color: #5A5A5A;
    font-family: 'InterMedium';
    font-size: 31.58px;
}
.page-header__wrapper{
    margin-left: auto;
    display: flex;
    align-items: center;
    gap:10px
}
body[dir='rtl'] .page-header__wrapper{
    margin-left: initial;
    margin-right: auto;
}

.page-header__link{
    /* text-decoration: underline; */
    color: #5885C3;
    border-bottom: 2px solid #AAAAAA;
    font-family: 'InterSemiBold';
    padding-bottom: 16px;
    font-size: 30px;
    display: flex;
    align-items: center;
    gap:22px
}
body[dir='rtl'] .page-header__link{
    font-family: 'CairoSemiBold';
}
@media (max-width:991px) {
    .page-header{
        margin-bottom: 20px;
        flex-direction: column;
        justify-content: center;
    }
    .page-header__title{
        font-size: 26.72px;
        gap: 8px;
        text-align: center;
    }
    .page-header__link{
        padding-bottom: 6px;
        font-size: 20px;
        gap: 22px;
        display: flex;
        /* margin-left: auto; */
        margin-right: initial;
        margin-left: initial;
    }
    body[dir='rtl'] .page-header__link{
        margin-right: initial;
        margin-left: initial;
    }
    .page-header__wrapper{
        margin-left: initial;
    }
    body[dir='rtl'] .page-header__wrapper{
        margin-left: initial;
        margin-right: initial;
    }
    
}