.sections__wrapper{
    display: flex;
    gap:40px
}
.navbar__wrapper{
    width:300px
}
.content__wrapper{
    max-width: calc(100vw - 320px);
    flex-grow: 1;
    position: relative;
    padding:40px ;
}
.sidebar__wrapper{
    width:300px
}
.navbar__responsive-button{
    display: none;
    position: absolute;
    top:15px;
    left:10px;
}
@media (max-width:1400px) {
    .sidebar__wrapper{
        display: none;
    }
    .content__wrapper{
        max-width: 100%;
        flex-grow: 1;
    }
}
@media (max-width:991px) {
    .sections__wrapper{
        /* padding:20px; */
        gap:0
    }
    .content__wrapper{
        max-width: 100%;
    }
    .navbar__wrapper{
        width:300px
    }
}
@media (max-width:765px) {
    .navbar__responsive-button{
        display: flex;
    }
    .navbar__wrapper{
        width: 0;
        position: absolute;
    }
}