.pagination__wrapper{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 0px 0 20px
}
.pagination__element {
    width: 30px;
    height: 30px;
    border: 3px solid var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'CairoBold';
}
.pagination__element--active {
    background-color: var(--main-color);
    color: white
}

@media (max-width:450px) {
    .loading__icon{
        width: 200px;
        height: 200px;
    }
}