
.appointments__table{
    width:100%;
    /* border-collapse: separate; */
    /* border-spacing: 0 15px; */
}
.appointments__table thead{
    background-color:var(--main-color)
}
.appointments__table thead th{
    padding: 12px;
    color: white;
}
.appointments__table td{
    vertical-align: middle;
    padding: 12px;
    color:#323232
}
.appointments__table-link{
    font-size: 16px;
    color:#5885C3;
    font-family: 'InterMedium';
    gap: 5px;
    display: flex;
}
body[dir='rtl'] .appointments__table-link{
    font-family: 'CairoMedium';
}
.appointments__table-name{
    font-size: 16px;
    color:black;
    font-family: 'InterMedium';
}
body[dir='rtl'] .appointments__table-name{
    font-family: 'CairoMedium';
}
.appointments__table-link-icon{
    width: 17px;
}
.appointments__table-delete{
    font-size: 16px;
    color:red;
    font-family: 'InterMedium';
}
body[dir='rtl'] .appointments__table-delete{
    font-family: 'CairoMedium';
}
.form__content-form-input{
    width: 70%;
    border-radius: 5px;
    background-color: white;
    color: #A0A0A0;
    border: 1.5px solid #D9D9D9!important;
    padding: 5px;
    font-size: 16px;
    font-family: 'InterRegular';
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form__content-form-input:focus{
    outline: 1px solid #d0c7c7;
}
@media (max-width:765px) {
    
}
@media (max-width:1024px) {
    
}
@media (max-width:500px) {
    
}