.login{
    background: url('/src/assets/imgs/loginBG.png') left no-repeat;
    background-size: contain;
    height:100vh;
    display: flex;
}
.login__content-wrapper{
    width:50%;
    /* background-color: white; */
    border-radius: 50px;
    display:flex;
    flex-direction: column;
    margin: auto;
    padding: 0px 90px;
}
.login__logo{
    margin: auto;
    margin-bottom: 60px;
    /* width:286px; */
    object-fit: contain;
}
.login__title{
    font-size: 25px;
    font-family: 'InterBold';
    text-align: center;
}
body[dir='rtl'] .login__title{
    font-family: 'CairoBold';
}
.login__sub-title{
    font-size: 18px;
    font-family: 'InterMedium';
    text-align: center;
    margin-bottom: 32px;
}
body[dir='rtl'] .login__sub-title{
    font-family: 'CairoBold';
}
.login__form{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:16px;
}
.login__input-wrapper{
    width:100%;
    position: relative;
}
.login__input{
    outline: none;
    border: 1.5px solid #3C3C3C;
    padding: 20px!important;
    color:#8C8C8C;
    font-size: 15px;
    border-radius: 10px;
    width: 100%;
}
.login__input-icon{
    position: absolute;
    right:10px;
    top:50%;
    transform: translateY(-50%);
    width: 31.17px;
    height:25.5px;
}
.login__button{
    width:350px;
    max-width: 100%;
    height:60px;
    background-color: var(--main-color);
    color:white;
    font-size: 18.77px;
    border-radius: 15px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin: auto;
    transition: var(--transition);
}
.login__button:hover{
    color: white;
    opacity: .8;
}
.login__powered-by{
    color: #4B4B4B;
    font-size: 25.09px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    margin-top: 90px;
}
.login__powered-by-logo{

}
@media (max-width:1024px) {
    .login__content-wrapper{
        width:70%;
    }
}

@media (max-width:768px) {
    .login__content-wrapper{
        width:90%;
        padding: 25px 30px;
    }
    .login__logo{
        margin-bottom: 40px;
        width:220px;
    }
}

@media (max-width:500px) {
    .login__content-wrapper{
        width:95%;
        padding: 25px 30px;
    }
    .login__logo{
        margin-bottom: 30px;
        width:180px;
    }
}